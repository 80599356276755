<template>
  <div class="d-flex flex-column text-center mt-3">

    <v-text-field
      v-if="worksheetTypeFields.hasTargetWeight"
      class="mx-auto"
      v-model.number="worksheet.targetWeight"
      :label="$vuetify.lang.t('$vuetify.base.targetWeight')"
      style="max-width: 200px; font-size: 26px"
      outlined
      type="number"
      inputmode="decimal"
      min="0"
      @change="$emit('update:worksheet', worksheet)"
    >
      <template v-slot:append> {{ productInfo ? productInfo.measureWeight:'' }} </template>
    </v-text-field>

    <v-text-field
      v-if="worksheetTypeFields.hasDeductibleWeight"
      class="mx-auto"
      v-model.number="worksheet.deductibleWeight"
      :label="$vuetify.lang.t('$vuetify.base.deductibleWeight')"
      style="max-width: 200px; font-size: 26px"
      outlined
      type="number"
      inputmode="decimal"
      min="0"
      @change="$emit('update:worksheet', worksheet)"
    >
      <template v-slot:append> {{ productInfo ? productInfo.measureWeight:'' }} </template>
    </v-text-field>

    <v-text-field
      v-if="worksheetTypeFields.hasNetAdjustmentWeight"
      class="mx-auto"
      v-model.number="worksheet.netAdjustmentWeight"
      :label="$vuetify.lang.t('$vuetify.base.netAdjustmentWeight')"
      style="max-width: 200px; font-size: 26px"
      outlined
      type="number"
      inputmode="decimal"
      min="0"
      @change="$emit('update:worksheet', worksheet)"
    >
      <template v-slot:append> {{ productInfo ? productInfo.measureWeight:'' }} </template>
    </v-text-field>

    <div
      class="d-flex justify-center mx-2 my-4"
    >
      <v-btn :disabled="worksheet.status == 'USED'" @click="updateWorksheet(worksheet)" color="primary">{{
        $vuetify.lang.t("$vuetify.base.update")
      }}</v-btn>
    </div>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    productInfo: {
      type: Object,
      required: false
    },
    worksheetTypeFields: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),

    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
  },
  created() {
  },
  methods: {
    ...mapActions("processWorksheet", [
      "updateProcessWorksheet",
      "deleteProcessWorksheet",
    ]),
    ...mapActions("messages", ["addErrorMessage", "addSuccessMessage"]),

    updateWorksheet(worksheet) {
      
      const payload = Object.assign({}, worksheet);
      delete payload.files;
      this.updateProcessWorksheet(payload)
        .then(() => {
          this.updateNetWeights();
          this.$emit('update:worksheet', worksheet);
          this.$emit('updated', worksheet);
        })
    },
    updateNetWeights() {
      if (this.worksheet.entries && this.worksheet.entries.length > 0) {
        this.worksheet.entries.forEach((entry) => {
          entry.netWeight =
            (entry.grossWeight ? entry.grossWeight : 0) -
            this.worksheet.deductibleWeight;
        });
      }
    },
  },
};
</script>

<style></style>
